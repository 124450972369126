<template>
     <div class="container-fluid">
         <SuccessAlert ref="MySuccessAlert" :title="AlertTitle" :message="AlertMessage" :back="true"/>
          <div class="row row-custom">
            <div class="col-md-8">
              <div class="card">
                <div class="card-header card-header-primary">
                  <h4 class="card-title">{{isNew?'اضافة كود خصم':'تعديل كود خصم'}}</h4>
                 </div>
                <div class="card-body">
                    <div class="row mb-3">
                      <Input id="code" @code="generateCode($event)" :show_button="true" title="كود الخصم" @Input="HandleInput('code',$event)" Mykey="code" :errors="service_error" :MyValue="form.code"/>
                      <Select id="type" title="التصنيف" :Items="CustomTypes" Mykey="type" :errors="service_error" :MyValue="form.type" @Select="HandleSelect('type',$event)"/>
                      <Input id="amount" title="القيمة" @Input="HandleInput('amount',$event)" Mykey="amount" :errors="service_error" :MyValue="form.amount"/>
                      <InputDate type="date" id="expire_date" title="تاريخ الانتهاء" @Input="HandleInput('expire_date',$event)" Mykey="expire_date" :errors="service_error" :MyValue="form.expire_date"/>
                      <InputToggle class_bs="col-md-12" v-model="form.status" :MyValue="form.status" id="status" title="حالة الكود" :errors="service_error" 
                                @GiveMeIfo="HandleToggle($event)"
                           />
                          
                    </div>

                    <button type="button" class="btn btn-primary pull-right" v-if="isNew"  @click="onSubmit()">حفظ </button>
                    <button type="button" class="btn btn-primary pull-right" v-else  @click="onUpdate()">تعديل </button>
                     <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import Input from '../../../components/Form/TextInput'
import InputFile from '../../../components/Form/InputFile'
import InputToggle from '../../../components/Form/InputToggle'
import InputDate from '../../../components/Form/InputDate'
import Select from '../../../components/Form/TextSelect'
import {mapState,mapMutations} from 'vuex';
import store from '../../../store/index'
import SuccessAlert from '../../../components/SuccessAlert'
export default {
  computed:{
    ...mapState({
        DiscountCode:state=>state.admin.discount.DiscountCode.data,
        service_error:state=>state.admin.discount.DiscountCode.errors,
    }),

    isNew(){
      return this.form.id == '';
    },
    CustomTypes(){
      return [
        {
          id:1,
          name:'قيمة'
        },
        {
          id:2,
          name:'نسبة'
        },
      ]
    }
 
   },
   data(){
       return{
           form:{
               id:'',
               code:'',
               status:false,
               type:'',
               amount:'',
               expire_date:'',
           },
           AlertTitle:'',
           AlertMessage:'',
 
       }
   },
   methods:{
    ...mapMutations({
          cleanErrors: "admin/cleanDiscountCodeErrors"
       }),
     setData(vm){       
            vm.form.id=vm.DiscountCode.id;
            vm.form.code=vm.DiscountCode.code;
            vm.form.status=vm.DiscountCode.status;
            vm.form.type=vm.DiscountCode.type;
            vm.form.amount=vm.DiscountCode.amount;
            vm.form.expire_date=vm.DiscountCode.expire_date;
        },
       
       onSubmit(){
     //    this.isLoading=true;
          store.commit('admin/PleaseStartLoading');        
          store.dispatch('admin/storeDiscountCode',this.form).then((response)=>{
             store.commit('admin/PleaseStopLoading');
            this.cleanErrors();
            this.AlertTitle="معلومات",
            this.AlertMessage="تم حفظ التغيرات بنجاح";
            this.$refs.MySuccessAlert.showModel();
          }).catch((error)=>{
          store.commit('admin/PleaseStopLoading');
          });
       },
       generateCode(val){
         this.form.code=val;
       },
       onUpdate(){
     //    this.isLoading=true;
          store.commit('admin/PleaseStartLoading');        
          let formData=new FormData(); 
          for (let key in this.form) { 
            formData.append(key, this.form[key]);
          }
          formData.append('_method', "patch");
          store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/updateDiscountCode',formData).then((response)=>{
             store.commit('admin/PleaseStopLoading');
            this.cleanErrors();
            this.AlertTitle="معلومات",
            this.AlertMessage="تم حفظ التغيرات بنجاح";
            this.$refs.MySuccessAlert.showModel();
          }).catch((error)=>{
          store.commit('admin/PleaseStopLoading');
          });
       },
  
     HandleInput(key,value){
         this.form[key]=value;
     },
     HandleNameInput(key,value){
         this.form['name'][key]=value;
     },
     HandleSelect(key,value){
         this.form[key]=value;
     },
     HandleToggle(value){
       this.form.status=value.val;
     }
   },
   components:{
       Input,
       SuccessAlert,
       InputFile,
       InputToggle,
       InputDate,
       Select
    },
   
     beforeRouteEnter(to,from,next){  
      //   store.commit('admin/PleaseStartLoading');
        const id = to.params.id; 

        if (typeof id === "undefined") {
            next(vm => {
       //       store.commit('admin/PleaseStopLoading');
              //  vm.isLoading = false;
            });
         } else {
             store.dispatch('admin/fetchDiscountCode', id).then((response) => {
                next(vm => {
                    vm.setData(vm);
         //           store.commit('admin/PleaseStopLoading');
               //     vm.isLoading = false;
                });


            });
        }
    },
      beforeDestroy(){
         this.cleanErrors();
     }
}
</script>

<style lang="scss">
/*
 *
 */
.row-custom{
    justify-content: center;
}


















</style>